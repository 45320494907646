.editProductData {
    padding: 1rem;
    width: 80%;
    margin: auto;
    background-color: var(--white-color);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
  
    h2 {
      margin-bottom: 1rem;
      font-size: var(--h1-font-size);
      color: var(--text-color);
      text-align: center;
      margin-right: 350px;
    }
  
    .error {
      color: red;
      margin-bottom: 1rem;
      text-align: center;
    }
  
    
    form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-left: 400px;
  
      .MuiTextField-root {
        width: 60%;
        background-color: var(--input-color);
        border-radius: 4px;
        font-size: large;
        
        .MuiInputBase-input {
          padding: 1rem;
          
          font-size: var(--normal-font-size);
          color: var(--black-color);
         align-items: center;
        }
  
        .MuiInputLabel-root {
          font-size: var(--h2-font-size);
          color: var(--black-color);
          font-weight: 500;
          
          
          
        }
  
        .MuiOutlinedInput-notchedOutline {
          border-color: var(--grey-text);
        }
  
        .Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: var(--primary-color);
        }
      }
  
      .MuiButton-root {
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 8px;
        padding: 0.5rem;
        color: var(--white-color);
        background-color: var(--primary-color);
        box-shadow: 0 2px 4px var(--pale-green);
        transition: background-color 0.3s;
        width:60%;
  
        &:hover {
          background-color: var(--hover-color);
        }
      }
    }
  }
  