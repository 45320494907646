/* state.css */

:root {
    --primary-color: hsl(94, 59%, 35%);
    --hover-color: #bdf094;
    --pale-green: hsl(96, 75%, 89%);
    --white-color: hsl(0, 0%, 100%);
    --black-color: hsl(0, 0%, 18%);
    --text-color: hsl(240, 1%, 48%);
    --bg-color: hsl(220, 10%, 94%);
    --grey-text: rgb(190, 190, 190);
    --input-color: hsl(330, 12%, 97%);

    --biggest-font-size: 2.5rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smallest-font-size: 0.75rem;
}

.statePage {
    padding: 1rem;
    width: 80%;
    margin: auto;
    max-height: 80vh; /* Adjust the max height as needed */
    overflow-y: auto; /* Ensure vertical scrollbar is always visible */
}

.statePage .landDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white-color);
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

.statePage .landDetails .imageContainer {
    position: relative;
    display: inline-block;
}

.statePage .landDetails .imageContainer img {
    width: 350px; /* Adjust the size as needed */
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.statePage .landDetails .imageContainer .swapButton {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    border-radius: 50%;
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.statePage .landDetails .imageContainer .swapButton svg {
    font-size: 1.5rem;
}

.statePage .landDetails .imageContainer .swapButton:hover {
    background-color: var(--hover-color);
}

.statePage .landDetails .details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 10rem;
}

.statePage .landDetails .details h2 {
    margin-bottom: 1rem;
    margin-left: 1px;
}

.statePage .landDetails .details p {
    margin: 0.5rem 0;
    font-size: var(--normal-font-size);
}

.statePage .detailsTable {
    margin-top: 2rem;
}

.statePage .detailsTable h3 {
    margin-bottom: 1rem;
}

.statePage .detailsTable table {
    width: max-content; /* Adjust width based on content */
    min-width: 100%; /* Minimum width */
    border-collapse: collapse;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--white-color); /* White background */
    table-layout: fixed; /* Fixed table layout */
}

.statePage .detailsTable table th,
.statePage .detailsTable table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid var(--grey-text);
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Display ellipsis for overflow */
    word-wrap: break-word; /* Wrap words if necessary */
}

.statePage .detailsTable table th {
    background-color: var(--primary-color); /* Primary color background for headers */
    color: var(--white-color); /* White text color for headers */
    word-wrap: break-word; /* Wrap words if necessary */
}

.statePage .detailsTable table tr:nth-child(even) {
    background-color: var(--bg-color); /* Alternate row background color */
}

.statePage .detailsTable table tr:hover {
    background-color: var(--hover-color); /* Hover background color */
}

.statePage .employeeDetails {
    margin-top: 2rem;
}

.statePage .employeeDetails h3 {
    margin-bottom: 1rem;
}

.statePage .employeeDetails .dataGrid {
    height: 400px; /* Adjust height as needed */
    width: 100%;
    background-color: var(--white-color); /* White background */
    border: 1px solid var(--grey-text); /* Grey border */
    border-radius: 8px;
}

.statePage .employeeDetails table {
    width: 100%; /* Full width table */
    border-collapse: collapse;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: var(--white-color); /* White background */
}

.statePage .employeeDetails table th,
.statePage .employeeDetails table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid var(--grey-text);
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Display ellipsis for overflow */
    word-wrap: break-word; /* Wrap words if necessary */
}

.statePage .employeeDetails table th {
    background-color: var(--primary-color); /* Primary color background for headers */
    color: var(--white-color); /* White text color for headers */
    word-wrap: break-word; /* Wrap words if necessary */
}

.statePage .employeeDetails table tr:nth-child(even) {
    background-color: var(--bg-color); /* Alternate row background color */
}

.statePage .employeeDetails table tr:hover {
    background-color: var(--hover-color); /* Hover background color */
}
