.datatable {
  height: 580px;
  padding: 20px;
  overflow: auto;
}
.datatableTitle {
  width: 100%;
  font-size: var(--h2-font-size);
  font-weight: 900;
  color: var(--black-color);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.link {
  text-decoration: none;
  text-align: center;
  color: hsl(94, 59%, 35%);
  font-size: 16px;
  font-weight: 400;
  border: 1px solid hsl(94, 59%, 35%);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  width: 180px;
}

.link:hover{
  background-color: hsl(94, 59%, 35%);
  color: white;
  font-weight: 700;
}

.cellWithImg {
  display: flex;
  align-items: center;
}

.cellAction {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 15px;
}
.viewButton {
  height: 40px;
  padding: 5px 20px;
  text-align: center;
  border-radius: 5px;
  color: darkblue;
  border: 1px dotted rgba(0, 0, 139, 0.596);
  cursor: pointer;
}

.viewButton:hover{
  background-color: darkblue;
  color: white;
}

.deleteButton {
  height: 40px;
  padding: 5px 20px;
  text-align: center;
  border-radius: 5px;
  color: crimson;
  border: 1px dotted rgba(220, 20, 60, 0.6);
  cursor: pointer;
}

.deleteButton:hover{
  background-color: crimson;
  color: white;
}
