.allProductData {
  padding: 1rem;
  width: 80%;
  margin: auto;
  max-height: 80vh; /* Adjust the max height as needed */
  overflow: auto; /* Enable both vertical and horizontal scrollbars */
}

.landDetailsList {
  padding: 20px;
}

h2 {
  margin-bottom: 20px;
  margin-left: 480px;
}

table {
  min-width: 750px;
  width: max-content; /* Allows the table to expand beyond its container width */
  table-layout: auto; /* Fix table layout to respect column widths */
}

thead {
  background-color: #f5f5f5;
}

th {
  font-weight: bold;
}

/* Style for action buttons in one line */
td:last-child {
  display: flex;
  gap: 11px;
  justify-content:left; 
  padding-top: 14px;/* Adjust alignment as needed */
}

/* Style for icons */
.icon-button {
  cursor: pointer; /* Display pointer cursor when hovering over icon */
  color: #707070; /* Adjust icon color as needed */
  font-size: 1.5rem; /* Adjust icon size as needed */
}

.icon-button:hover {
  color: #333; /* Darken icon color on hover */
}
