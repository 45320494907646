:root {
    --primary-color: hsl(94, 59%, 35%);
    --hover-color: #bdf094;
    --pale-green: hsl(96, 75%, 89%);
    --white-color: hsl(0, 0%, 100%);
    --black-color: hsl(0, 0%, 18%);
    --text-color: hsl(240, 1%, 48%);
    --bg-color: hsl(220, 10%, 94%);
    --grey-text: rgb(190, 190, 190);
    --input-color: hsl(330, 12%, 97%);
    --itemCard-color: hsl(120, 5%, 88%);
    --itemCard-Hover: hsl(120, 27%, 87%);

    --biggest-font-size: 2.5rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smallest-font-size: 0.75rem;
}

.listingSection {
    flex-basis: 70%;

    .heading {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            font-size: var(--h2-font-size);
            color: var(--black-color);
            font-weight: 700;
        }

        .btn {
            display: flex;
            align-items: center;
            border: 1px solid transparent;
            font-weight: 500;
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: border 0.3s ease;

            &:hover {
                border: 1px solid var(--primary-color);
            }

            .icon {
                margin-left: 0.5rem;
            }
        }
    }

    .secContainer {
        width: 100%;
        margin: 1rem 0;
        display: flex;
        gap: 1rem;
        
        justify-content: space-between;

        .singleItem {
            position: relative;
            width: 170px;
            height: 230px;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 1rem;
            border-radius: 10px;
            background: var(--itemCard-color);
            transition: background 0.3s ease, box-shadow 0.3s ease;

            &:hover {
                background: var(--itemCard-Hover);
                box-shadow: 0 2px 2px var(--input-color);
            }

            img {
                width: 100px;
                height: auto;
            }

            .icon {
                position: absolute;
                top: 10px;
                right: 10px;
                color: var(--primary-color);
                cursor: pointer;
                z-index: 1;
            }

            h3 {
                font-size: var(--h3-font-size);
                color: var(--black-color);
                padding: 1rem 0;
            }
        }

        .addLandItem {
            position: relative;
            width: 170px;
            height: 230px;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 1rem;
            border-radius: 10px;
            background: var(--itemCard-color);
            transition: background 0.3s ease, box-shadow 0.3s ease;

            &:hover {
                background: var(--itemCard-Hover);
                box-shadow: 0 2px 2px var(--input-color);
            }

            .addLandLink {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-decoration: none;
                color: var(--black-color);
                
                .addIcon {
                    font-size: 4rem; /* Adjust size as needed */
                    color: var(--primary-color);
                    cursor: pointer;
                    margin-top: 55px; /* Adjust position as needed */
                }
                
                h3 {
                  font-size: var(--h3-font-size);
                  color: var(--black-color);
                  padding: 1rem 0;
                }
              }
        }
    }

    .sellers {
        width: 100%;
        justify-content: space-between;
        gap: 1rem;
        align-items: flex-start;

        .topSellers, .featuredSellers {
            .heading {
                padding: 1rem 0;

                .btn {
                    padding: 1rem 1rem;
                }
            }

            .card {
                padding: 1rem;
                border-radius: 1rem;
                background: var(--white-color);
                gap: 1rem;
                box-shadow: 0 2px 4px var(--input-color);

                .users {
                    padding-right: 1rem;
                    border-right: 2px solid var(--grey-text);

                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        object-fit: cover;
                        border: 2px solid var(--input-color);
                        transition: 0.5s ease;

                        &:hover {
                            transform: translateY(-10px);
                        }
                    }
                }

                .cardText {
                    span {
                        color: var(--black-color);
                        font-weight: 500;

                        small {
                            font-weight: 400;

                            .date {
                                margin-left: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

