
.edit-employee-page__content {
  margin: 0 20px ;
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: flex-start;
  justify-items: start;
}

 .title {
  margin-bottom: 20px;
  color: hsl(94, 59%, 35%);
  font-size: 1.5rem;
  font-weight: bold;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.text_inputs{
  display: flex;
  flex-direction: column;
}

 .textfield {
  margin-bottom: 20px;
  width: 400px;
}

.MuiInputBase-root {
  height: 50px;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  outline: none;
}

.MuiInputBase-root:focus {
  border: 1px solid hsl(94, 59%, 35%);
}

.bottom_button{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.button-save{
  padding: 8px 16px;
  color: white;
  border-radius: 5px;
  background-color:hsl(94, 59%, 35%) ;
  border: none;
  cursor: pointer;
}

.button-save:hover{
  color: hsl(94, 59%, 35%);
  border: 1px solid hsl(94, 59%, 35%);
  background-color: white;
  
}
