:root {
  --primary-color: hsl(94, 59%, 35%);
  --hover-color: #bdf094;
  --pale-green: hsl(96, 75%, 89%);
  --white-color: hsl(0, 0%, 100%);
  --black-color: hsl(0, 0%, 18%);
  --text-color: hsl(240, 1%, 48%);
  --bg-color: hsl(220, 10%, 94%);
  --grey-text: rgb(190, 190, 190);
  --input-color: hsl(330, 12%, 97%);

  --biggest-font-size: 2.5rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smallest-font-size: 0.75rem;
}

.landDetailsForm {
  padding: 1rem;
  width: 80%;
  margin: auto;
  background-color: var(--white-color);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-height: 80vh; /* Adjust the max height as needed */
  overflow-y: auto; /* Ensure vertical scrollbar is always visible */

  h2 {
    margin-bottom: 1rem;
    margin-left: 0px;
    font-size: var(--h1-font-size);
    color: var(--text-color);
  }

  form {
    display: flex;
    flex: 5;
    flex-direction: row;
    justify-content: space-between;

    .form-left{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 1rem;
    }

    .form-group {
      margin-bottom: 1rem;


      label {
        margin-bottom: 0.5rem;
        font-size: var(--normal-font-size);
        color: var(--text-color);
      }

      input {
        padding: 0.5rem;
        border: 1px solid var(--bg-color);
        border-radius: 4px;
        font-size: var(--normal-font-size);
        color: var(--black-color);
        background-color: var(--input-color);
        width: 200%;

        &:focus {
          outline: none;
          border-color: var(--primary-color);
        }
      }

      .error {
        color: red;
        font-size: var(--small-font-size); /* Adjust font size if needed */
        margin-top: 4px; /* Add some margin for separation */
      }
    }

    .submitButton {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      margin-top: 270px;
      background-color: var(--primary-color);
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: var(--hover-color);
      }

      .icon {
        margin-left: 0.5rem; /* Adjust spacing between icon and text */
      }
    }
  }
}
