@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

* {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    transition: .3s ease;
}

:root {
    --primary-color: hsl(94, 59%, 35%);
    --hover-color: #bdf094;
    --pale-green: hsl(96, 75%, 89%);
    --white-color: hsl(0, 0%, 100%);
    --black-color: hsl(0, 0%, 18%);
    --text-color: hsl(240, 1%, 48%);
    --bg-color: hsl(220, 10%, 94%);
    --grey-text: rgb(190, 190, 190);
    --input-color: hsl(330, 12%, 97%);

    --biggest-font-size: 2.5rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smallest-font-size: 0.75rem;
}

html {
    font-size: 16px; /* Ensure a consistent base font size */
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

.icon {
    font-size: var(--h2-font-size);
    cursor: pointer;
}

img {
    width: 100%;
    height: auto;
}

.flex {
    display: flex;
    align-items: center;
}

.grid {
    display: grid;
    align-items: center;
    gap: 1.5rem;
}

.btn {
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0 2px 4px var(--input-color);
    padding: 0.8rem 1rem;
    color: var(--text-color);
}

.btn .icon {
    margin-left: 0.5rem;
}

.btn:hover {
    background: var(--pale-green);
    color: var(--primary-color);
}

body {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 1rem;
}

.container {
    position: relative;
    width: 95vw;
    height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-color);
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: 2px 2px 8px var(--grey-text);
}


