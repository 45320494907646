:root {
    --primary-color: hsl(94, 59%, 35%);
    --hover-color: #bdf094;
    --pale-green: hsl(96, 75%, 89%);
    --white-color: hsl(0, 0%, 100%);
    --black-color: hsl(0, 0%, 18%);
    --text-color: hsl(240, 1%, 48%);
    --bg-color: hsl(220, 10%, 94%);
    --grey-text: rgb(190, 190, 190);
    --input-color: hsl(330, 12%, 97%);
  
    --biggest-font-size: 2.5rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smallest-font-size: 0.75rem;
  }
  
  .topSection {
    .headerSection {
      display: flex;
      justify-content: space-between;
  
      .title {
        h1 {
          font-size: var(--h1-font-size);
          color: var(--black-color);
          font-weight: 720;
        }
  
        p {
          font-size: var(--normal-font-size);
          color: var(--text-color);
          font-weight: 500;
        }
      }
  
      .searchBar {
        display: flex;
        padding: 1rem 2rem;
        background: var(--white-color);
        border-radius: 5px;
        gap: 1rem;
        box-shadow: 0 2px 4px var(--input-color);
  
        input {
          border: none;
          outline: none;
          background: none;
        }
  
        .icon {
          color: var(--text-color);
  
          &:hover {
            color: var(--primary-color);
          }
        }
      }
  
      .adminDiv {
        display: flex;
        gap: 1rem;
  
        .icon {
          font-size: var(--biggest-font-size);
          background: var(--white-color);
          border-radius: 5px;
          padding: 5px;
          box-shadow: 0 2px 4px var(--input-color);
          color: var(--text-color);
        }
  
        .adminImage {
          border: 3px solid var(--white-color);
          border-radius: 10px;
          width: 1.8rem;
          overflow: hidden;
          box-shadow: 0 2px 4px var(--input-color);
  
          img {
            width: 100%;
            height: 120%;
            border-radius: 10px;
          }
        }
      }
    }
  
    .cardSection {
      display: flex;
      margin-top: 3rem;
      gap: 1rem;
  
      .rightCard {
        position: relative;
        display: flex;
        flex-basis: 70%;
        height: 200px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: auto;
        padding: 2rem;
        border-radius: 1rem;
        overflow: hidden;
  
        .videoDiv {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
  
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
  
        h1,
        p,
        .btn {
          z-index: 100;
        }
  
        h1 {
          font-size: var(--h1-font-size);
          color: var(--white-color);
          font-weight: 800;
        }
  
        p {
          padding: 1rem;
          color: var(--pale-green);
          font-weight: 500;
        }
  
        .buttons {
          display: flex;
          gap: 1rem;
  
          .btn {
            padding: 0.5rem 1rem;  // Adjust padding to suitable values
            color: var(--primary-color);
            border: 2px solid transparent;
            box-shadow: none;
          }
  
          .transparent {
            background: none;
            border: 2px solid var(--pale-green);
            color: var(--pale-green);
  
            &:hover {
              background: var(--pale-green);
              color: var(--primary-color);
            }
          }
        }
      }

      .leftCard{
        flex-basis: 50%;
        .main{
            position: relative;
            padding: 1rem;
            height: 200px;
            width: 420px;
            border-radius: 1rem;
            justify-content: space-between;

            &::before{
                content: '';
                position: absolute;
                height: 100%;
                width: 85%;
                background: var(--pale-green);
                left: 0;
                bottom: 0;
                border-radius: 1rem;

            }
            .textDiv{
                z-index: 100;

                h1{
                    font-size: var(--h1-font-size);
                    padding-bottom: 1rem;
                    color: var(--black-color);
                }
                .flex{
                    gap: 1.5rem;
                    font-weight: 500;
                    color: var(--black-color);

                    small{
                        font-size: var(--normal-font-size);
                        display: block;
                        color: var(--primary-color);
                        font-weight: 500;
                        padding: .5rem 0;
                    }
                }

                .Link{
                   margin-top: 2rem;
                   cursor: pointer;
                   
                   &:hover{
                    color: var(--primary-color);
                    .icon{
                        transform: translateX(10px);
                        transition: .3s ease;
                    }
                   }
                }
            }

            .imgDiv{
               transform: translateY(-0px);
                

               img{
                width: 210px;
               }
            }
        }
      }
    }
  }
  