:root {
    --primary-color: hsl(94, 59%, 35%);
    --hover-color: #bdf094;
    --pale-green: hsl(96, 75%, 89%);
    --white-color: hsl(0, 0%, 100%);
    --black-color: hsl(0, 0%, 18%);
    --text-color: hsl(240, 1%, 48%);
    --bg-color: hsl(220, 10%, 94%);
    --grey-text: rgb(190, 190, 190);
    --input-color: hsl(330, 12%, 97%);

    --biggest-font-size: 2.5rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smallest-font-size: 0.75rem;
}

.sideBar {
    background: var(--white-color);
    box-shadow: 1px 0px 4px var(--grey-text);
    width: 18%;
    height: 100%;
    gap: 2.5rem;
    overflow: auto;
  
    .logoDiv {
      padding: 1.5rem 1.5rem 0;
      cursor: pointer;
  
      img {
        max-width: 30px;
        margin-right: 0.5rem;
        background: var(--input-color);
        border-radius: 2px;
        padding: 0.2rem;
      }
  
      h2 {
        font-size: var(--h2-font-size);
        color: var(--primary-color);
        position:relative;
        margin-left:10px;
        margin-bottom: 5px;
      }
    }
  
    .menuDiv, .settingsDiv {
      .divTitle {
        font-size: var(--h3-font-size);
        color: var(--black-color);
        padding: 0 1.5rem 1.5rem;
      }
  
      .menuLists {
        .listItem {
          padding: 0.2rem 1.5rem;
          width: 100%;
          position: relative;
  
          &::before {
            position: absolute;
            content: '';
            height: 0%;
            left: 0;
            width: 5px;
            bottom: 0;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            background-color: var(--primary-color);
          }
  
          .menuLink {
            color: var(--grey-text);
            font-weight: 500;
            transition: 0;
            .icon {
              margin-right: 0.5rem;
            }
          }
  
          &:hover .menuLink {
            color: var(--primary-color);
            transition: 0;
          }
  
          &:hover::before {
            height: 100%;
            transition: 0.3s ease;
          }
        }
  
        .dropdownMenu {
          list-style: none;
          padding: 0;
          margin: 0;
          padding-left: 1rem;
  
          .dropdownItem {
            padding: 0.2rem 1.5rem;
            width: 100%;
            position: relative;
  
            .menuLink {
              color: var(--grey-text);
              font-weight: 500;
              transition: 0;
            }
  
            &:hover .menuLink {
              color: var(--primary-color);
              transition: 0;
            }
          }
        }
      }
    }

    .sideBarCard{
        width:100%;
        padding: 1rem;
        text-align: center;
        position: relative;

        .icon{
            position: absolute;
            background: var(--hover-color);
            border: 10px solid var(--white-color);
            font-size: 3rem;
            border-radius: 50%;
            top: -8px;
            right: 50%;
            transform: translate(50%);
            z-index: 100;
        }
        .cardContent{
            position: relative;
            padding: 1rem;
            background: var(--hover-color);
            border-radius: 10px;
            overflow: hidden;

            h3{
                font-size: var(--h3-font-size);
                margin-top: 1rem;
                padding: 1rem 0;
                font-weight: 800;
                color: var(--black-color);
            }
            p{
                font-size: var(--normal-font-size);
                color: var(--text-color);
                padding-bottom: 1rem;
                font-weight: 500;
            }
            .btn{
                position: relative;
                color: var(--text-color);
                z-index: 1000;
            }
            .circle1 , .circle2{
                position: absolute;
                background: var(--pale-green);
                border-radius: 50%;
                opacity: .7;
            }
            .circle1{
                height: 100px;
                width: 100px;
                top: -50px;
                left: -50px;
            }
            .circle2{
                height: 130px;
                width: 130px;
                bottom: -80px;
                right: -70px;
                z-index: 1;
            }
            
            
        }
    }
}