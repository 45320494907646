:root {
    --primary-color: hsl(94, 59%, 35%);
    --hover-color: #bdf094;
    --pale-green: hsl(96, 75%, 89%);
    --white-color: hsl(0, 0%, 100%);
    --black-color: hsl(0, 0%, 18%);
    --text-color: hsl(240, 1%, 48%);
    --bg-color: hsl(220, 10%, 94%);
    --grey-text: rgb(190, 190, 190);
    --input-color: hsl(330, 12%, 97%);
    --itemCard-color:hsl(120,5% ,88%);
    --itemCard-hover:hsl(120,27% ,87%);


    --biggest-font-size: 2.5rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smallest-font-size: 0.75rem;
}

.activitySection{
    flex-basis: 50%;

    .heading{
        width: 100%;
        justify-content: space-between;

        h1{
            font-size: var(--h2-font-size);
            color:var(--black-color);
            font-weight: 700;
        }

        .btn{
            border: 1px solid transparent;
        }
        .btn :hover{
            border: 1px solid var(--primary-color);
        }
    }

    .secContainer{
        margin-top: 1 rem;

        .singleCustomer{
            align-items: flex-start;
            justify-content: space-between;

            img{
                width:40px;
                height:40px;
                object-fit: cover; 
                border-radius: 50%;
                box-shadow: 0 2px 4px var(--itemCard-color);  
                margin-right: 1rem;        
             }

             .customerDetails{
                flex:1;

                .name{
                    font-size: var(--h3-font-size);
                    font-weight: 600;
                    color: var(--black-color);
                    display: block;
                }
                small{
                    font-weight: 500;
                    color:var(--text-color);
                    font-size: var(--smallest-font-size);
                }
             }
        }

    }
}