:root {
    --primary-color: hsl(94, 59%, 35%);
    --hover-color: #bdf094;
    --pale-green: hsl(96, 75%, 89%);
    --white-color: hsl(0, 0%, 100%);
    --black-color: hsl(0, 0%, 18%);
    --text-color: hsl(240, 1%, 48%);
    --bg-color: hsl(220, 10%, 94%);
    --grey-text: rgb(190, 190, 190);
    --input-color: hsl(330, 12%, 97%);
    
    --biggest-font-size: 2.5rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smallest-font-size: 0.75rem;
  }
  
  .landDetailPage {
    padding: 1rem;
    width: 80%;
    margin: auto;
    background-color: var(--white-color);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-height: 80vh;
    overflow-y: auto;
    position: relative; /* Ensure positioning context for the icon */
  
    .backIcon {
      position: absolute;
      top: 20px;
      left: 20px;
      cursor: pointer;
      color: var(--primary-color);
      transition: color 0.3s;
  
      &:hover {
        color: var(--hover-color);
      }
    }
  
    .detailContainer {
      width: 80%;
      max-width: 600px;
      padding: 2rem;
      margin: auto;
      background-color: var(--white-color);
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      text-align: left;
    }
  
    h2 {
      margin-bottom: 1rem;
      font-size: var(--h1-font-size);
      color: var(--text-color);
      text-align: center;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      .form-group {
        margin-bottom: 1.5rem;
        position: relative;
  
        label {
          display: block;
          margin-bottom: 0.5rem;
          font-size: var(--normal-font-size);
          color: var(--black-color);
          font-weight: 500;
        }
  
        input {
          width: 100%;
          padding: 0.8rem;
          font-size: var(--normal-font-size);
          border: 1px solid var(--grey-text);
          border-radius: 4px;
          background-color: var(--input-color);
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
          color: var(--black-color);
          transition: border-color 0.3s;
  
          &:focus {
            border-color: var(--primary-color);
            outline: none;
          }
        }
  
        .icon {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          font-size: 1.5rem;
          color: var(--primary-color);
          cursor: pointer;
        }
      }
  
      .btn {
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 8px;
        padding: 1rem;
        color: var(--white-color);
        background-color: var(--primary-color);
        box-shadow: 0 2px 4px var(--pale-green);
        transition: background-color 0.3s;
  
        &:hover {
          background-color: var(--hover-color);
        }
      }
    }
  }
  