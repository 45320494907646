.new {
  width: 100%;
  display: flex;
}

.newContainer .top {
  padding: 10px;
  margin: 0px 20px;
  display: flex;
  flex-direction: row;
}

.top h1 {
  font-size: var(--h2-font-size);
  color: var(--black-color);
  font-weight: 700;
}
.newContainer .bottom {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  margin: 20px;
  display: flex;
}

.right {
  flex: 2;
}

.newContainer .bottom .right form {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
}

.formInput {
  width: 50%;
}

.formInput label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--black-color);
}

 .formInput input {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: rgb(228, 228, 226);
  border-color: rgb(180, 180, 177);
  border-radius: 5px;
}

.submit_button{
  width: 150px;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: hsl(94, 59%, 35%);
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}

.list {
  display: flex;
  width: 100%;
}

.list .listContainer {
  flex: 6;
}

.link_back{
  text-decoration: none;
  color: hsl(94, 59%, 35%);
  font-size: 14px;
  font-weight: bold;
  border: 1px solid hsl(94, 59%, 35%);
  padding: 5px ;
  border-radius: 5px;
  cursor: pointer;
}

.link_back:hover{
  background-color: hsl(94, 59%, 35%);
  color: white;
  font-weight: bold;
}

.required {
  color: red;
}
