:root {
    --primary-color: hsl(94, 59%, 35%);
    --hover-color: hsl(94, 59%, 45%);
    --white-color: hsl(0, 0%, 100%);
    --black-color: hsl(0, 0%, 18%);
    --bg-color: hsl(220, 10%, 94%);
    --table-header-color: hsl(220, 10%, 74%);


        --primary-color: hsl(94, 59%, 35%);
        --hover-color: #bdf094;
        --pale-green: hsl(96, 75%, 89%);
        --white-color: hsl(0, 0%, 100%);
        --black-color: hsl(0, 0%, 18%);
        --text-color: hsl(240, 1%, 48%);
        --bg-color: hsl(220, 10%, 94%);
        --grey-text: rgb(190, 190, 190);
        --input-color: hsl(330, 12%, 97%);
      
        --biggest-font-size: 2.5rem;
        --h1-font-size: 1.5rem;
        --h2-font-size: 1.25rem;
        --h3-font-size: 1rem;
        --normal-font-size: 0.938rem;
        --small-font-size: 0.813rem;
        --smallest-font-size: 0.75rem;
      
  }
  
  .landDetailsContainer {
    padding: 1rem;
    padding-bottom: 25rem;
    width: 80%;
    margin: auto;
    max-height: 80vh; /* Adjust the max height as needed */
    overflow-y: auto; /* Ensure vertical scrollbar is always visible */


  }
  
  
  .pageTitle {
    font-size: 1.5rem;
    color: var(--text-color);
    margin-bottom: 1rem;

    
  }
  
  .landTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  
    th, td {
      border: 1px solid var(--table-header-color);
      padding: 0.75rem;
      text-align: left;
    }
  
    th {
      background-color: var(--table-header-color);
      color: var(--black-color);
      font-weight: 600;
    }
  
    tbody tr:nth-child(even) {
      background-color: var(--white-color);
    }
  
    tbody tr:nth-child(odd) {
      background-color: var(--bg-color);
    }
  }
  
  .viewButton, .deleteButton {
    border: none;
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  .viewButton {
    background-color: hsl(240, 100%, 85%);
    color: hsl(240, 100%, 30%);
  }
  
  .deleteButton {
    background-color: hsl(0, 100%, 90%);
    color: hsl(0, 100%, 40%);
  }
  
  .addLandButton {
    display: block;
    margin: 0 auto;
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: var(--hover-color);
    }
  }
  